// import

@import "libs/mixins/myMixin";
@import "libs/resets/sanitize";
@import "libs/slick/slick.scss";
@import "libs/slick/slick-theme.scss";
@import "libs/modaal/modaal";

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:500,700,900|Roboto:500,700&display=swap');

/* ================================================== *
/*
/*    COLOR
/*
/* ================================================== */

// typeA

$typeAprimaryDefault: #E60313;
$typeAprimaryDark: #99000D;

// typeB

$typeBprimaryDefault: #E6A442;
$typeBprimaryDark: #D17323;

// common

$fontColor1: #3B3B3B;
$fontColor2: darken(#B5B5B5,20);
$fontColor3: $typeAprimaryDark;
$fontColor4: $typeAprimaryDefault;
$borderColor1: #E9E9E9;
$labelColor1: #60B7E2;
$labelColor2: #EF8686;
$bgColor1: #F5F5F5;
$opacity: 0.7;
$sectionMargin1: 30px;
$sectionMargin2: 60px;
$documentWidth1: 1170+100px;
$hoverShadow1: 0 0 15px rgba(#000,0.1);


/* ================================================== *
/*
/*    BASE
/*
/* ================================================== */

body {
  font-family: 'Roboto', 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  @include fontsizeDefault;
  color: #000;
  background: $bgColor1;
  &.-calendar {
    background: #fff;
  }
  &.-LP {
    @include min-sm {
      min-width: 1240px;
    }
  }
}

a {
  color: #000;
  text-decoration: none;
  transition: all .1s linear;
  @include fontsizeDefault;
  &:visited {
    color: $fontColor1;
  }
  &:hover {
    opacity: $opacity;
    text-decoration: none;
  }
}

img {
  width: 100%;
  height: auto;
}

*:focus {
  outline: none !important;
}

.main1__inner1 {
  padding: 0 15px;
  @include min-sm {
    padding: 0;
    width: 100%;
    max-width: $documentWidth1;
    margin: auto;
    padding: 0 50px;
  }
}

.main2 {
  background: #fff;
  margin: 0 0 -45px 0;
  @include min-sm {
    margin: 0 0 -90px 0;
  }
}

/* ================================================== *
/*
/*    GLOBAL HELPER
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        -clearFix
/* ------------------------------------------------- */

.-clearFix {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

/* ------------------------------------------------- *
/*        -hide
/* ------------------------------------------------- */

.-hide {
  display: none;
}

/* ------------------------------------------------- *
/*        visible
/* ------------------------------------------------- */

.-spOnly {
  display: block;
  @include min-sm {
    display: none;
  }
}

.-pcOnly {
  display: none;
  @include min-sm {
    display: block;
  }
}


/* ================================================== *
/*
/*    BUTTON
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        button1
/* ------------------------------------------------- */

a.button1 {
  display: inline-block;
  margin: 0 0 0 5px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: $fontColor1;
  text-align: center;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
}

/* ------------------------------------------------- *
/*        button2
/* ------------------------------------------------- */

a.button2 {
  display: block;
  background: #fff;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    display: inline-block;
    vertical-align: middle;
    margin: -6px 12px 0 0;
    transform: rotate(135deg);
  }
  &:after {
    content: 'もっと見る';
  }
  &.-active1 {
    &:before {
      margin-top: 2px;
      transform: rotate(-45deg);
    }
    &:after {
      content: '閉じる';
    }
  }
}

/* ================================================== *
/*
/*    LINK
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        link1
/* ------------------------------------------------- */

a.link1 {
  position: relative;
  display: inline-block;
  &:before {
    position: relative;
    top: 5px;
    content: '';
    display: inline-block;
    width: 19px;
    height: 19px;
    background: $typeAprimaryDefault;
    border-radius: 10px;
    margin: 0 10px 0 0;
    @at-root {
      body.-typeB a.link1:before {
        background: $typeBprimaryDefault;
      }
    }
  }
  &:after {
    position: absolute;
    left: 5px;
    right: 0;
    top: 11px;
    bottom: 0;
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
  }
}

/* ------------------------------------------------- *
/*        link2
/* ------------------------------------------------- */

a.link2 {
  @extend .link1;
  &:after {
    left: 8px;
    transform: rotate(-135deg);
  }
}

/* ================================================== *
/*
/*    LABEL
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        label1
/* ------------------------------------------------- */

.label1 {
  @include fontsizeSmall;
  line-height: 1;
  color: #fff;
  text-align: center;
  width: 60px;
  height: 30px;
  line-height: 30px;
  display: block;
  font-weight: bold;
  &.-type1 {
    background: $labelColor1;
  }
  &.-type2 {
    background: $labelColor2;
  }
}

/* ================================================== *
/*
/*    TABLE
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        table1
/* ------------------------------------------------- */

.table1 {
  table {
    width: 100%;
    @include min-sm {
      border-bottom: 1px solid $borderColor1;
    }
  }
  th,td {
    @include fontsizeSmall;
    display: block;
    padding: 10px 20px;
    border-top: 1px solid $borderColor1;
    text-align: center;
    @include min-sm {
      padding: 15px 30px;
      text-align: left;
      display: table-cell;
    }
  }
  th {
    background: $bgColor1;
    width: 100%;
  }
  td {
    @include fontsizeH3;
    color: $fontColor3;
    font-weight: bold;
    white-space: nowrap;
    span {
      display: inline-block;
      margin: 0 0 0 5px;
      @include fontsizeDefault;
    }
  }
  br {
    @include min-sm {
      display: none;
    }
  }
}

/* ------------------------------------------------- *
/*        table2
/* ------------------------------------------------- */

.table2 {
  position: relative;
  @extend .table1;
  th,td {
    text-align: left;
  }
  th {
    white-space: nowrap;
    @include min-sm {
      width: 200px;
      text-align: center;
    }
  }
  td {
    @include fontsizeDefault;
    color: $fontColor1;
    white-space: normal;
  }
}

/* ------------------------------------------------- *
/*        table3
/* ------------------------------------------------- */

.table3 {
  @extend .table2;
  table {
    word-break: break-all;
    word-wrap: break-word;
  }
  th,td {
    @include min-sm {
      width: 25%;
      display: table-cell;
    }
    &:empty {
      display: none;
      @include min-sm {
        display: table-cell;
      }
    }
  }
  th {
    @include min-sm {
      text-align: left;
      white-space: normal;
    }
  }
}

/* ================================================== *
/*
/*    TITLE
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        title1
/* ------------------------------------------------- */

.title1 {
  background: #fff;
  padding: 15px;
  @include min-md {
    padding: 25px 150px 25px 30px;
    position: relative;
  }
}
.title1__title1 {
  @include fontsizeH4;
  border-left: 7px solid $typeAprimaryDefault;
  padding: 0 0 0 10px;
  @include min-md {
    padding-left: 20px;
  }
  @at-root {
    body.-typeB .title1__title1 {
      border-color: $typeBprimaryDefault;
    }
  }
}
.title1__date1 {
  display: inline-block;
  margin: 10px 0 0 0;
  font-weight: normal;
  @include min-md {
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    height: 1em;
    line-height: 1;
    margin: auto;
  }
}

/* ------------------------------------------------- *
/*        title2
/* ------------------------------------------------- */

.title2 {
  @include fontsizeH5;
  position: relative;
  padding: 0 0 0 24px;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '■';
    display: block;
    color: $typeAprimaryDefault;
    @at-root {
      body.-typeB .title2:before {
        color: $typeBprimaryDefault;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        heading1
/* ------------------------------------------------- */

.heading1 {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 13.8%;
  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(right, #fff 30%, rgba(#fff,0));
    background: linear-gradient(to right, #fff 30%, rgba(#fff,0));
  }
}
.heading1__inner1 {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 30px;
  height: 100%;
  display: flex;
  align-items: center;
  @include min-sm {
    max-width: 1170px;
    right: 0;
    padding-left: 50px;
    padding-right: 50px;
    margin: auto;
  }
  @include min-md {
    padding: 80px 50px;
  }
  @include min-lg {
    max-width: $documentWidth1;
    margin: auto;
  }
}
.heading1__title {
  position: relative;
  z-index: 10;
  @include fontsizeH3;
}


/* ================================================== *
/*
/*    BLOCK
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        header1
/* ------------------------------------------------- */

.header1 {
  background: #fff;
}
.header1__inner1 {
  padding: 25px 15px;
  @include min-sm {
    position: relative;
    width: 100%;
    max-width: $documentWidth1;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
  }
  @include min-lg {
    padding: 15px 50px;
  }
}
.header1__siteName1 {
  display: flex;
  align-items: center;
}
.header1__siteName1__logo1 {
  width: 60px;
  height: 60px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 10px 0 0;
  @include min-sm {
    margin: 0 20px 0 0;
  }
}
.header1__siteName1__siteName1 {
  a {
    @include fontsizeH5;
    color: $fontColor1;
  }
}
.header1__contact1 {
  position: fixed;
  z-index: 300;
  left: 0;
  bottom: 0;
  width: 100%;
  background: $typeAprimaryDefault;
  text-align: center;
  padding: 15px;
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  @include min-sm {
    width: auto;
    position: static;
    background: none;
  }
  @include min-lg {
    right: 50px;
  }
  @include min-md {
    top: 5px;
  }
  @at-root {
    body.-typeB .header1__contact1 {
      background: $typeBprimaryDefault;
      @include min-sm {
        background: none;
      }
    }
  }
  &.-hide {
    bottom: -120px;
  }
}
.header1__contact1__tel1 {
  a {
    color: #fff;
    @include fontsizeH1;
    font-weight: bold;
    line-height: 1;
    @include min-sm {
      color: $fontColor1;
      pointer-events: none;
    }
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      background: url('/assets/images/icon_tel1_2.svg') 0 0 no-repeat;
      background-size: 24px;
      margin: -3px 10px 0 0;
      @include min-sm {
        width: 29px;
        height: 29px;
        background: url('/assets/images/icon_tel1_1.svg') 0 0 no-repeat;
        background-size: 29px;
      }
      @at-root {
        body.-typeB .header1__contact1__tel1 a:before {
          @include min-sm {
            background: url('/assets/images/icon_tel1_3.svg') 0 0 no-repeat;
          }
        }
      }
    }
  }
}
.header1__contact1__text1 {
  color: #fff;
  line-height: 1;
  margin: 10px 0 0 0;
  @include fontsizeSmall;
  @include min-sm {
    color: $fontColor1;
    margin-top: 5px;
  }
}
.header1__contact1__btnTop1 {
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  a {
    width: 40px;
    height: 40px;
    background: #fff;
    border: 1px solid $typeAprimaryDefault;
    color: transparent;
    position: absolute;
    top: -20px;
    right: 10px;
    display: block;
    @at-root {
      body.-typeB .header1__contact1__btnTop1 a {
        border-color: $typeBprimaryDefault;
      }
    }
    &:hover {
      opacity: 1;
    }
    @include min-sm {
      position: fixed;
      top: inherit;
      bottom: 30px;
      right: 30px;
      width: 50px;
      height: 50px;
    }
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-top: 2px solid $typeAprimaryDefault;
      border-right: 2px solid $typeAprimaryDefault;
      transform: rotate(-45deg);
      position: absolute;
      left: 0;
      right: 0;
      top: 2px;
      bottom: 0;
      margin: auto;
      @at-root {
        body.-typeB .header1__contact1__btnTop1 a:before {
          border-color: $typeBprimaryDefault;
        }
      }
      @include min-sm {
        width: 14px;
        height: 14px;
        top: 4px;
      }
    }
  }
  &.-hide {
    opacity: 0;
  }
}

/* ------------------------------------------------- *
/*        header2
/* ------------------------------------------------- */

.header2__logo1 {
  padding: 50px 0 0 0;
  max-width: 80%;
  margin: auto;
  @include min-sm {
    width: 722px;
    padding: 160px 0 0 0;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.header2__catch1 {
  font-size: 24px;
  line-height: 1.58;
  font-weight: 900;
  text-align: center;
  color: #6D6E70;
  margin: 40px 0 0 0;
  padding: 0 0 50px 0;
  @include min-sm {
    padding-bottom: 160px;
  }
  span {
    color: #45B035;
  }
  br {
    @include min-sm {
      display: none;
    }
  }
}
.header2__feature1 {
  background: #ABCD03;
  padding: 30px;
  @include min-sm {
    padding: 40px 0;
  }
}
.header2__feature1__list1 {
  list-style: none;
  margin: 0;
  padding: 0;
  @include min-sm {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.header2__feature1__list1__unit1 {
  background: #45B035;
  border-radius: 10px;
  padding: 20px 30px 20px 30+28+20px;
  position: relative;
  margin: 10px 0 0 0;
  @include min-sm {
    max-width: 210px;
    min-width: 210px;
    min-height: 210px;
    padding: 40px 20px;
    border-radius: 210px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0 15px;
  }
  &:first-child {
    margin-top: 0;
    @include min-sm {
      margin-left: 0;
    }
  }
  &:last-child {
    @include min-md {
      margin-right: 0;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    margin: auto;
    content: '';
    display: block;
    width: 28px;
    height: 24px;
    background: url('/assets/images/icon_heart1_1.png') 0 0 no-repeat;
    background-size: 28px auto;
    @include min-sm {
      top: 20px;
      bottom: inherit;
      left: 0;
      right: 0;
    }
  }
  br {
    display: none;
    @include min-sm {
      display: block;
    }
  }
}
.header2__feature1__list1__unit1__text1 {
  color: #fff;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 900;
  width: 100%;
  span {
    font-size: 15px;
    line-height: 1;
    display: block;
    font-weight: 700;
    margin: 0 0 5px 0;
    @include min-sm {
      margin-bottom: 10px;
    }
  }
}

/* ------------------------------------------------- *
/*        topicPath1
/* ------------------------------------------------- */

.topicPath1 {
  padding: 0 30px;
  margin: 15px 0 0 0;
  @include min-sm {
    padding: 0 50px;
  }
  @include min-lg {
    max-width: $documentWidth1;
    margin: auto;
    margin-top: 15px;
  }
}
.topicPath1__list1 {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    &:first-child {
      margin-left: auto;
    }
    &:before {
      content: '>';
      display: inline-block;
      margin: 0 5px;
      @at-root {
        li {
          &:first-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  a {
    color: $typeAprimaryDefault;
    text-decoration: underline;
    @at-root {
      body.-typeB .topicPath1__list1 a {
        color: $typeBprimaryDefault;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        wp-pagenavi
/* ------------------------------------------------- */

.wp-pagenavi {
  margin: 30px 0 0 0;
  @include min-sm {
    margin-top: 60px;
  }
  span,
  a {
    display: inline-block;
    vertical-align: top;
    background: $typeAprimaryDefault;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    transition: all .1s linear;
    @at-root {
      body.-typeB .wp-pagenavi span,
      body.-typeB .wp-pagenavi a {
        background: $typeBprimaryDefault;
      }
    }
    &:hover {
      text-decoration: none;
      background: $typeAprimaryDark;
      opacity: 1;
      @at-root {
        body.-typeB .wp-pagenavi span,
        body.-typeB .wp-pagenavi a {
          &:hover {
            background: $typeBprimaryDark;
          }
        }
      }
    }
    @include min-sm {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
  span {
    background: #fff;
    color: $typeAprimaryDefault;
    @at-root {
      body.-typeB .wp-pagenavi span {
        background: #fff;
        color: $typeBprimaryDefault;
      }
    }
    &:hover {
      background: #fff;
      @at-root {
        body.-typeB .wp-pagenavi span:hover {
          background: #fff;
        }
      }
    }
  }
  .extend {
    display: none;
  }
  .pages {
    display: none;
  }
  .last,
  .first {
    display: none;
  }
  .nextpostslink,
  .previouspostslink {
    display: none;
  }
}

/* ------------------------------------------------- *
/*        globalNav1
/* ------------------------------------------------- */

.globalNav1 {
  @include min-md {
    background: $typeAprimaryDefault;
    width: 100%;
  }
  @at-root {
    body.-typeB .globalNav1 {
      background: $typeBprimaryDefault;
    }
  }
}
.globalNav1__btnToggle1 {
  background: $typeAprimaryDefault;
  padding: 15px;
  font-weight: bold;
  text-align: center;
  @include min-md {
    display: none;
  }
  @at-root {
    body.-typeB .globalNav1__btnToggle1 {
      background: $typeBprimaryDefault;
    }
  }
  div {
    color: #fff;
    @include fontsizeH5;
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      display: inline-block;
      vertical-align: middle;
      margin: -6px 0 0 12px;
      transform: rotate(135deg);
    }
  }
  &.-active {
    div {
      &:after {
        transform: rotate(-45deg);
        margin-top: 2px;
      }
    }
  }
}
.js-globalNav1__navlist1 {
  display: none;
  @include min-md {
    display: block !important;
  }
}
.globalNav1__navlist1 {
  background: $typeAprimaryDark;
  display: flex;
  flex-wrap: wrap;
  @include min-md {
    position: static;
    display: flex !important;
    background: none;
    width: 100%;
    max-width: $documentWidth1;
    margin: auto;
    padding: 15px 0;
  }
  @include min-lg {
    padding: 15px 50px;
  }
  @at-root {
    body.-typeB .globalNav1__navlist1 {
      background: $typeBprimaryDark;
      @include min-md {
        background: none;
      }
    }
  }
  li {
    width: 50%;
    border-bottom: 1px solid rgba(#fff,.2);
    @include min-md {
      width: 16.6%;
      border: none;
      border-left: 1px solid rgba(#fff,0.4);
    }
    &:first-child {
      @include min-md {
        width: 13.6%;
      }
    }
    &:last-child {
      border: none;
      @include min-md {
        width: 19.6%;
        border-right: 1px solid rgba(#fff,0.4);
        border-left: 1px solid rgba(#fff,0.4);
      }
    }
  }
  a {
    padding: 20px;
    text-align: center;
    display: block;
    color: #fff;
    font-weight: bold;
    position: relative;
    @include min-sm {
      padding: 10px 5px 15px;
    }
    &.-arrow1 {
      &:before {
        @include min-md {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0px;
          margin: auto;
          content: '';
          display: block;
          width: 7px;
          height: 7px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(135deg);
        }
      }
    }
    &.-active {
      &:before {
        content: '';
        display: block;
        width: 70px;
        height: 5px;
        background: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -15px;
        margin: auto;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        footer1
/* ------------------------------------------------- */

.footer1 {
  margin: 45px 0 0 0;
  background: $typeAprimaryDefault;
  @include min-sm {
    margin-top: 90px;
  }
  @at-root {
    body.-typeB .footer1 {
      background: $typeBprimaryDefault;
    }
  }
}
.footer1__btnTop1 {
  border-top: 1px solid $borderColor1;
  a {
    display: block;
    color: transparent;
    background: #fff;
    line-height: 0;
    padding: 25px 15px 15px;
    &:hover {
      opacity: 1;
    }
    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-top: 2px solid $typeAprimaryDefault;
      border-right: 2px solid $typeAprimaryDefault;
      transform: rotate(-45deg);
      margin: auto;
      @include min-sm {
        width: 18px;
        height: 18px;
      }
      @at-root {
        body.-typeB .footer1__btnTop1 a:before {
          border-color: $typeBprimaryDefault;
        }
      }
    }
  }
}
.footer1__wrapper1 {
  @include min-md {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  @include min-lg {
    padding: 60px 0;
  }
}
.footer1__contact1 {
  padding: 30px 30px 0;
  text-align: center;
  @include min-sm {
    padding: 40px 0 20px;
    background: none;
    display: flex;
    max-width: 650px;
    margin: auto;
    align-items: center;
  }
  @include min-lg {
    padding: 0;
  }
}
.footer1__contact1__title1 {
  @include fontsizeH5;
  color: #fff;
  @include min-sm {
    text-align: left;
    padding: 0 60px 0 0;
    font-weight: bold;
    white-space: nowrap;
  }
  br {
    display: none;
    @include min-sm {
      display: block;
    }
  }
}
.footer1__contact1__body1 {
  @include min-sm {
    padding: 0 0 0 60px;
    border-left: 1px solid rgba(#fff,0.4);
  }
}
.footer1__contact1__body1__tel1 {
  a {
    display: block;
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    line-height: 1;
    white-space: nowrap;
    @include min-sm {
      font-size: 54px;
      pointer-events: none;
    }
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 28px;
      height: 28px;
      background: url('/assets/images/icon_tel1_2.svg') 0 0 no-repeat;
      background-size: 28px;
      margin: -3px 10px 0 0;
      @include min-sm {
        width: 38px;
        height: 38px;
        line-height: 38px;
        background-size: 38px;
        margin: -5px 15px 0 0;
      }
    }
  }
}
.footer1__contact1__body1__text1 {
  color: #fff;
  margin: 10px 0 0 0;
  line-height: 1.4;
  @include min-sm {
    margin-left: 8px;
  }
}
.footer1__body1 {
  margin: 30px 30px 0;
  @include fontsizeSmall;
  color: #fff;
  text-align: center;
  border-top: 1px solid rgba(#fff,0.3);
  padding: 20px 0;
  @include min-sm {
    text-align: left;
    max-width: 650px;
    min-width: 650px;
    margin: 20px auto 0;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
  @include min-lg {
    display: block;
    max-width: auto;
    min-width: auto;
    padding: 0;
    margin: 0;
    border: none;
  }
}
.footer1__body1__text1 {
  @include min-md {
    border: none;
    padding: 0;
  }
}
.footer1__body1__link1 {
  margin: 15px 0 0;
  @include min-md {
    margin-bottom: 0;
  }
  a {
    color: #fff;
    @include fontsizeSmall;
  }
}
.footer1__copy1 {
  display: block;
  background: $typeAprimaryDark;
  text-align: center;
  color: #fff;
  @include fontsizeSmall;
  padding: 15px;
  @include min-sm {
    padding: 25px;
  }
  @at-root {
    body.-typeB .footer1__copy1 {
      background: $typeBprimaryDark;
    }
  }
}

/* ------------------------------------------------- *
/*        kv1
/* ------------------------------------------------- */

.kv1 {
  position: relative;
  overflow: hidden;
  .slick-arrow {
    z-index: 10;
    background: $typeAprimaryDefault;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    display: none !important;
    @include min-sm {
      display: block !important;
    }
    @at-root {
      body.-typeB .kv1 .slick-arrow {
        background: $typeBprimaryDefault;
      }
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 14px;
      height: 14px;
      color: transparent;
    }
  }
  .slick-prev {
    left: 30px;
    @include min-lg {
      left: calc(50vw - 605px);
    }
    &:before {
      left: 4px;
      display: inline-block;
      border-top: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: rotate(-45deg);
    }
  }
  .slick-next {
    right: 30px;
    @include min-lg {
      right: calc(50vw - 605px);
    }
    &:before {
      right: 4px;
      display: inline-block;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
    }
  }
  .slick-dots {
    position: static;
    @include min-sm {
      position: absolute;
      bottom: 35px;
      z-index: 10;
    }
    li {
      width: 10px;
      height: 10px;
      button {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #fff;
        &:before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background: $typeAprimaryDefault;
          @at-root {
            body.-typeB .kv1 .slick-dots li.slick-active button {
              background: $typeBprimaryDefault;
            }
          }
        }
      }
    }
  }
}
.kv1__mask1 {
  // position: absolute;
  // left: 50%;
  // transform: translateX(-1755px);
  // top: 0;
  // display: flex;
  // z-index: 10;
  // margin: auto;
  // overflow: hidden;
  // div {
  //   @include min-lg {
  //     min-width: 1170px;
  //     height: 665px;
  //     background: rgba(#fff,0.8);
  //   }
  //   &:nth-of-type(2) {
  //     @include min-lg {
  //       margin-left: auto;
  //     }
  //   }
  // }
  @include min-lg {
    position: absolute;
    top: 0;
    left: 50vw;
    width: 1170px;
    height: 665px;
    transform: translateX(-1755px);
    background: rgba(#fff,0.8);
    z-index: 10;
  }
}
.kv1__mask2 {
  @include min-lg {
    position: absolute;
    top: 0;
    right: 50vw;
    width: 1170px;
    height: 665px;
    transform: translateX(1755px);
    background: rgba(#fff,0.8);
    z-index: 10;
  }
}
.kv1__slider1 {
  img {
    width: 100vw;
    height: auto;
    @include min-lg {
      width: 1170px;
    }
  }
}

/* ------------------------------------------------- *
/*        xo-event-calendar
/* ------------------------------------------------- */

.xo-event-calendar {
  background: #fff;
  table {
    border: none !important;
  }
  thead {
    th {
      color: #fff !important;
      background: #E60313;
      padding: 5px 0 !important;
    }
  }
  th,
  td {
    padding: 2px 0 !important;
    border: none !important;
  }
  .holiday-i ,
  .holiday-h ,
  .holiday-g ,
  .holiday-f ,
  .holiday-e ,
  .holiday-d ,
  .holiday-c ,
  .holiday-b ,
  .holiday-a {
    background: #E60313 !important;
    color: #fff !important;
    font-weight: bold;
  }
  .today {
    color: #000 !important;
  }
}
.xo-event-calendar table.xo-month {
  &>tbody {
    &>tr {
      &:first-child {
        td {
          padding-top: 10px !important;
        }
      }
    }
  }
}
.xo-event-calendar table.xo-month .month-next ,
.xo-event-calendar table.xo-month .month-prev {
  top: -5px !important;
}
.xo-event-calendar table.xo-month > caption {
  margin-bottom: 10px;
}
.xo-event-calendar table.xo-month > thead th {
  margin-bottom: 15px;
}
.xo-event-calendar table.xo-month .month-header > span {
  font-size: 24px;
  font-weight: bold;
}
.xo-event-calendar table.xo-month .month-dayname td div {
  width: 30px !important;
  height: 30px !important;
  border: none;
  padding: 0 !important;
  text-align: center !important;
  line-height: 30px !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  margin: auto !important;
  @include min-sm {
    font-size: 16px !important;
  }
}
.xo-event-calendar table.xo-month .month-event-space ,
.xo-event-calendar table.xo-month .month-dayname-space {
  display: none !important;
}
.xo-event-calendar p.holiday-title {
  span {
    background: $typeAprimaryDefault !important;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    padding: 0;
    border: #000;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -2px;
    @at-root {
      body.-typeB .xo-event-calendar p.holiday-title span {
        background: $typeBprimaryDefault !important;
      }
    }
  }
}
.xo-event-calendar table.xo-month .month-dayname {
  position: static !important;
}

/* ------------------------------------------------- *
/*        modal1
/* ------------------------------------------------- */

.modal1 {
  .table2 {
    margin: 20px 0 0 0;
  }
}

/* ------------------------------------------------- *
/*        block1
/* ------------------------------------------------- */

.block1 {
  @include sectionMargin1;
}
.block1__btnMore1 {
  margin: 2px 0 0 0;
}
.block1__unit1 {
  background: #fff;
  margin: 2px 0 0 0;
  @include min-sm {
    padding: 30px;
    position: relative;
    display: flex;
  }
  @include min-md {
    align-items: center;
  }
}
.block1__unit1__image1 {
  position: relative;
  @include min-sm {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
    margin: 0 45px 0 0;
  }
  @include min-md {
    width: 420px;
    min-width: 420px;
    max-width: 420px;
    border-right: 1px solid $borderColor1;
    padding: 0 45px 0 0;
  }
  @at-root {
    body.-typeB .block1__unit1__image1>div {
      @include min-sm {
        border-radius: 7px;
      }
    }
  }
  &>div {
    padding-top: 75%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .label1 {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.block1__unit1__body1 {
  padding: 20px;
  @include min-sm {
    padding: 0;
    border-left: 1px solid $borderColor1;
    padding: 0 0 0 45px;
  }
  @include min-md {
    border: none;
    padding: 0;
  }
}
.block1__unit1__body1__title1 {
  @include fontsizeH3;
  @include min-sm {
    margin: -10px 0 0 0;
  }
  @include min-md {
    margin: 0;
    display: flex;
    align-items: center;
  }
  span {
    @include fontsizeDefault;
    margin: 0 0 5px 0;
    display: inline-block;
    @include min-md {
      white-space: nowrap;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      margin: 0 30px 0 0;
    }
  }
}
.block1__unit1__body1__list1 {
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border-top: 1px solid $borderColor1;
  @include min-sm {
    padding: 0;
    margin: 30px 0 0 0;
    border: none;
  }
  dt {
    color: $fontColor2;
    margin: 5px 0 0 0;
    @include min-md {
      width: 100px;
      float: left;
      margin: 0 30px 0 0;
    }
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: '■';
      @include min-md {
        display: none;
      }
    }
  }
  dd {
    @include min-md {
      overflow: hidden;
      margin: 0 0 0 0;
    }
  }
}
.block1__linkMore1 {
  margin: 20px 0 0 0;
  text-align: right;
  @include min-sm {
    margin-top: 30px;
  }
}

/* ------------------------------------------------- *
/*        block2
/* ------------------------------------------------- */

.block2 {
  @include sectionMargin1;
}
.block2__btnMore1 {
  margin: 2px 0 0 0;
}
a.block2__unit1,
.block2__unit1 {
  @extend .block1__unit1;
  color: $fontColor1;
  display: block;
  position: relative;
  @include min-sm {
  }
}
a.block2__unit1 {
  &:hover {
    position: relative;
    z-index: 10;
    opacity: 1;
    box-shadow: $hoverShadow1;
  }
}
a.block2__unit1 {
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: auto;
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background: $typeAprimaryDefault;
    border-radius: 24px;
    display: none;
    @at-root {
      body.-typeB a.block2__unit1 {
        &:before {
          background: $typeBprimaryDefault;
        }
      }
    }
    @include min-lg {
      display: block;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 39px;
    margin: auto;
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    display: none;
    @include min-lg {
      display: block;
    }
  }
}
.block2__unit1__image1 {
  @include min-sm {
    min-width: 220px;
    max-width: 220px;
    margin: 0 45px 0 0;
  }
  @include min-md {
    border: none;
    padding: 0;
    width: 375px;
    min-width: 375px;
    max-width: 375px;
  }
  @include min-lg {
    width: 420px;
    min-width: 420px;
    max-width: 420px;
    padding-right: 45px;
    border-right: 1px solid $borderColor1;
  }
  @at-root {
    body.-typeB .block2__unit1__image1>div {
      @include min-sm {
        border-radius: 7px;
      }
    }
  }
  &>div {
    padding: 75% 0 0 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  p {
    color: $fontColor3;
    font-weight: bold;
    padding: 0 20px;
    margin: 20px 0 0 0;
    text-align: center;
    @include fontsizeH1;
    line-height: 1;
    span {
      display: inline-block;
      margin: 0 0 0 5px;
      @include fontsizeH4;
    }
  }
}
.block2__unit1__body1 {
  @extend .block1__unit1__body1;
  @include min-md {
    width: 100%;
    padding: 0 0 0 45px;
    border-left: 1px solid $borderColor1;
  }
  @include min-lg {
    border: none;
    padding: 0;
  }
}
.block2__unit1__body1__title1 {
  @extend .block1__unit1__body1__title1;
  position: relative;
  padding: 0 30px 0 0;
}
a.block2__unit1 .block2__unit1__body1__title1 {
  &:before {
    position: absolute;
    top: 0;
    bottom: -30px;
    right: 0;
    margin: auto;
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background: $typeAprimaryDefault;
    border-radius: 24px;
    @at-root {
      body.-typeB a.block2__unit1 .block2__unit1__body1__title1 {
        &:before {
          background: $typeBprimaryDefault;
        }
      }
    }
    @include min-md {
      bottom: 0;
    }
    @include min-lg {
      display: none;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    bottom: -30px;
    right: 9px;
    margin: auto;
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    @include min-md {
      bottom: 0;
    }
    @include min-lg {
      display: none;
    }
  }
}
.block2__unit1__body1__list1 {
  @extend .block1__unit1__body1__list1;
}
.block2__unit1__body1__list2 {
  @extend .block1__unit1__body1__list1;
  display: flex;
  flex-wrap: wrap;
  dt {
    width: 60%;
    &:before {
      display: none;
    }
    @include min-md {
      width: 60%;
      margin: 0;
    }
    @include min-lg {
      width: 25%;
    }
    &:nth-of-type(even) {
      @include min-lg {
        width: 20%;
      }
    }
    &:nth-of-type(5) {
      @include min-md {
        width: 60%;
      }
      @include min-lg {
        width: 25%;
      }
    }
  }
  dd {
    width: 40%;
    margin-top: 5px;
    text-align: right;
    @include min-md {
      width: 40%;
    }
    @include min-lg {
      width: 25%;
      text-align: left;
      margin: 0;
    }
    &:nth-of-type(odd) {
      @include min-lg {
        width: 20%;
      }
    }
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
}
.block2__linkMore1 {
  @extend .block1__linkMore1;
}

/* ------------------------------------------------- *
/*        block3
/* ------------------------------------------------- */

.block3 {
  @include sectionMargin1;
}
.block3__btnMore1 {
  margin: 2px 0 0 0;
}
.block3__unit1 {
  background: #fff;
  margin: 2px 0 0 0;
  position: relative;
  min-height: 180px;
  @include min-md {
    min-height: 270px;
  }
}
.block3__unit1__body1 {
  padding: 20px;
  @include min-sm {
    padding: 30px 210px 30px 30px;
  }
  @include min-md {
    padding: 40px 380px 40px 60px;
  }
}
.block3__unit1__body1__text1 {
  margin: 15px 0 0 0;
}
.block3__unit1__body1__table1 {
  margin: 30px 0 0 0;
  @include min-sm {
    margin-top: 30px;
  }
}
.block3__unit1__image1 {
  margin: 15px 0 0 0;
  @include min-sm {
    width: 150px;
    position: absolute;
    right: 30px;
    top: 30px;
    margin: 0;
  }
  @include min-md {
    width: 250px;
    top: 40px;
    right: 60px;
  }
  @at-root {
    body.-typeB .block3__unit1__image1>div {
      border-radius: 7px;
    }
  }
  &>div {
    padding-top: 75%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.block3__linkMore1 {
  @extend .block1__linkMore1;
}

/* ------------------------------------------------- *
/*        block4
/* ------------------------------------------------- */

.block4 {
  @include sectionMargin1;
  @include min-sm {
    margin-top: $sectionMargin2;
  }
}
.block4__list1 {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    @include min-sm {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1px;
    }
  }
  li {
    @include min-sm {
      width: 33.333%;
      display: flex;
      padding: 0 1px;
    }
  }
}
.block4__btnMore1 {
  margin: 3px 0 0 0;
}
.block4__unit1 ,
a.block4__unit1 {
  display: block;
  color: $fontColor1;
  background: #fff;
  @include min-sm {
    display: flex;
  }
  &:hover {
    position: relative;
    z-index: 10;
    opacity: 1;
    box-shadow: $hoverShadow1;
  }
}
.block4__unit1 {
  &:hover {
    box-shadow: none;
  }
}
.block4__linkMore1 {
  margin: 20px 0 0 0;
  text-align: right;
  @include min-sm {
    margin-top: 30px;
  }
}
.block4__unit1__image1 {
  padding-top: 75%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f1f1f1;
}
.block4__unit1__body1 {
  padding: 20px;
  @include min-sm {
    padding: 30px;
  }
}
.block4__unit1__body1__date1 {
  @include fontsizeSmall;
}
.block4__unit1__body1__title1 {
  @include fontsizeDefault;
  margin: 5px 0 0 0;
}
.block4__unit1__body1__text1 {
  @include fontsizeSmall;
  margin: 5px 0 0 0;
}
.block4__linkMore1 {
  @extend .block1__linkMore1;
}

/* ------------------------------------------------- *
/*        block5
/* ------------------------------------------------- */

.block5 {
  @include sectionMargin1;
}
.block5__btnMore1 {
  margin: 2px 0 0 0;
}
.block5__list1 {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
.block5__unit1 {
  background: #fff;
  margin: 2px 0 0 0;
  @include min-sm {
    display: flex;
    padding: 30px;
  }
  @include min-md {
    padding: 40px 60px;
  }
}
.block5__unit1__image1 {
  @include min-sm {
    min-width: 160px;
    margin: 0 30px 0 0;
  }
  @include min-md {
    min-width: 200px;
    margin: 0 60px 0 0;
  }
  @at-root {
    body.-typeB .block5__unit1__image1>div {
      @include min-sm {
        border-radius: 7px;
      }
    }
  }
  &>div {
    padding-top: 75%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.block5__unit1__body1 {
  padding: 20px;
  @include min-sm {
    padding: 0;
  }
}
.block5__unit1__name1 {
  span {
    @include fontsizeSmall;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 0 0 15px;
    line-height: 1;
    @include min-sm {
    }
  }
}
.block5__unit1__text1 {
  @include fontsizeSmall;
  margin: 10px 0 0 0;
}
.block5__linkMore1 {
  @extend .block1__linkMore1;
}

/* ------------------------------------------------- *
/*        block6
/* ------------------------------------------------- */

.block6 {
  @include sectionMargin1;
}
.block6__unit1 {
  background: #fff;
  margin: 2px 0 0 0;
}
.block6__btnMore1 {
  margin: 2px 0 0 0;
}
.block6__unit1__header1 {
  @include min-sm {
    display: flex;
  }
}
.block6__unit1__header1__shop1 {
  font-size: 0;
  @include min-sm {
    width: 50%;
  }
  .slick-arrow {
    z-index: 10;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    text-align: center;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 14px;
      height: 14px;
      color: transparent;
    }
  }
  .slick-prev {
    left: 10px;
    @include min-sm {
      left: 20px;
    }
    &:before {
      left: 4px;
      display: inline-block;
      border-top: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: rotate(-45deg);
    }
  }
  .slick-next {
    right: 10px;
    @include min-sm {
      right: 20px;
    }
    &:before {
      right: 4px;
      display: inline-block;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
    }
  }
}
.block6__unit1__header1__shop1__slider1 {
  &>div {
    width: 100%;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.block6__unit1__header1__shop1__slider1__image1 {
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.block6__unit1__header1__map1 {
  @include min-sm {
    width: 50%;
  }
  iframe {
    width: 100%;
    @include min-sm {
      height: 100%;
    }
  }
}
.block6__unit1__image1 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    &:nth-of-type(3) {
      width: 100%;
      @include min-sm {
        width: 50%;
      }
    }
    &:nth-of-type(2) ,
    &:nth-of-type(1) {
      width: 50%;
      @include min-sm {
        width: 25%;
      }
    }
  }
  iframe {
    width: 100%;
    height: 200px;
    @include min-sm {
      height: 100%;
    }
  }
}
.block6__unit1__body1 {
  padding: 20px;
  @include min-sm {
    display: flex;
    width: 100%;
    padding: 30px;
    justify-content: space-between;
  }
  @include min-md {
    padding: 40px 60px;
  }
  &>div {
    &:first-child {
      @include min-sm {
        padding: 0 30px 0 0;
      }
      @include min-md {
        padding-right: 60px;
      }
    }
  }
}
.block6__unit1__body1__title1 {
  @include fontsizeH4;
  margin: 10px 0 0 0;
  @include min-sm {
    margin: 0;
  }
}
.block6__unit1__body1__text1 {
  margin: 10px 0 0 0;
  @include min-sm {
    margin-top: 20px;
  }
}
.block6__unit1__body1__text2 {
  margin: 10px 0 0 0;
  @include min-sm {
    margin-top: 20px;
  }
}
.block6__unit1__body1__list1 {
  margin: 30px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  dt {
    font-weight: bold;
    width: 100%;
    margin: 0 0 10px 0;
    @include fontsizeH5;
  }
  dd {
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.3;
    @include min-md {
      font-size: 13px;
      margin: 0 0 0 20px;
    }
    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
  img {
    width: 40px;
    height: 40px;
    margin: 0 5px 0 0;
    @include min-md {
      width: 50px;
      height: 50px;
    }
    @include min-lg {
      width: 60px;
      height: 60px;
    }
  }
}
.block6__unit1__body1__calender1 {
  margin: 30px 0 0 0;
  background: #fff;
  @include min-sm {
    margin: 0;
    padding: 0;
    width: 300px;
    max-width: 300px;
    min-width: 300px;
  }
  @include min-md {
    margin: 0;
    padding: 0;
    width: 350px;
    max-width: 350px;
    min-width: 350px;
  }
  iframe {
    width: 100%;
    height: 318px;
  }
}
.block6__linkMore1 {
  @extend .block1__linkMore1;
}

/* ------------------------------------------------- *
/*        block7
/* ------------------------------------------------- */

.block7 {
  @include sectionMargin1;
}
.block7__header1 {
  background: #fff;
  margin: 2px 0 0 0;
  padding: 20px;
  @include min-sm {
    padding: 30px;
    display: flex;
  }
  @include min-md {
    padding: 40px 60px;
  }
}
.block7__header1__image1 {
  float: left;
  min-width: 100px;
  margin: 0 20px 10px 0;
  @at-root {
    body.-typeB .block7__header1__image1>div {
      border-radius: 7px;
    }
  }
  @include min-sm {
    min-width: 150px;
    margin: 0 30px 0 0;
    float: none;
  }
  @include min-md {
    min-width: 200px;
    margin: 0 60px 0 0;
  }
  &>div {
    padding-top: 75%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.block7__header1__body1 {
  @include min-sm {
    overflow: hidden;
    margin: 0 0 30px 0;
  }
}
.block7__header1__body1__title1 {
  @include fontsizeH5;
  @include min-sm {
    display: flex;
    align-items: center;
  }
  span {
    display: block;
    @include fontsizeDefault;
    margin: 5px 0 0 0;
    @include min-sm {
      margin: 0 0 0 15px;
    }
  }
}
.block7__header1__body1__text1 {
  margin: 15px 0 0 0;
  clear: both;
}
.block7__table1 {
  padding: 20px;
  background: #fff;
  @include min-sm {
    padding: 0 30px 30px;
  }
  @include min-md {
    padding: 0 60px 40px;
  }
}
.block7__linkMore1 {
  @extend .block1__linkMore1;
}

/* ------------------------------------------------- *
/*        block8
/* ------------------------------------------------- */

.block8 {
  @include sectionMargin1;
}
.block8__body {
  background: #fff;
  margin: 2px 0 0 0;
  padding: 20px;
  @include min-sm {
    padding: 30px;
  }
  @include min-md {
    padding: 60px;
  }
}
.block8__body__image1 {
  margin: 0 0 45px 0;
  @at-root {
    body.-typeB .block8__body__image1 img {
      border-radius: 7px;
    }
  }
}
.block8__body__text1 {
  h2 {
    margin: 45px 0 0 0;
    @include fontsizeH3;
    &:first-child {
      margin: 0;
    }
  }
  h3 {
    margin: 45px 0 0 0;
    @include fontsizeH4;
    &:first-child {
      margin: 0;
    }
  }
  h4 {
    margin: 45px 0 0 0;
    @include fontsizeH5;
    &:first-child {
      margin: 0;
    }
  }
  p {
    margin: 30px 0 0 0;
    &:first-child {
      margin: 0;
    }
  }
}
.block8__body__imageList1 {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 45px 0 0 0;
  @include min-sm {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }
  li {
    margin: 10px 0 0 0;
    @include min-sm {
      padding: 0 15px;
      width: 33.333%;
      margin: 0;
    }
    &:first-child {
      margin: 0;
    }
    &:last-child {
    }
  }
  img {
    @at-root {
      body.-typeB .block8__body__imageList1 img {
        border-radius: 7px;
      }
    }
  }
}
.block8__linkBack1 {
  margin: 30px 0 0 0;
  @include min-md {
    margin: 60px 0 0 0;
  }
}

/* ------------------------------------------------- *
/*        block9
/* ------------------------------------------------- */

.block9 {
  background: #fff;
  padding: 20px;
  @include sectionMargin1;
  @include min-sm {
    padding: 30px;
    display: flex;
    justify-content: space-between;
  }
  @include min-md {
    padding: 60px;
  }
}
.block9__image1 {
  @include min-sm {
    min-width: 40%;
    max-width: 40%;
  }
  @include min-md {
    min-width: 45%;
    max-width: 45%;
  }
  @include min-lg {
    min-width: 540px;
    max-width: 540px;
  }
}
.block9__image1__mainImage1 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding: 75% 0 0 0;
  li {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: none;
    &.-active {
      display: block;
    }
  }
}
.block9__image1__mainImage1__image1 {
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.block9__image1__thumbnail1 {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  @include min-md {
    margin: 20px -2px 0;
  }
  li {
    width: 25%;
    @include min-md {
      width: 25%;
      margin: 3px 0 0 0;
      padding: 0 2px;
    }
  }
}
.block9__image1__thumbnail1__image1 {
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.block9__body1 {
  margin: 30px 0 0 0;
  @include min-sm {
    margin: 0;
    width: 100%;
    padding: 0 0 0 45px;
  }
  @include min-md {
    padding: 0 0 0 60px;
  }
}
.block9__body1__header1__name1 {
  @include fontsizeH2;
  margin: 5px 0 0 0;
}
.block9__body1__header1__type1 {
  margin: 5px 0 0 0;
}
.block9__body1__header1__color1 {
  margin: 20px 0 0 0;
  span {
    color: $fontColor2;
    display: block;
    margin: 0 0 5px 0;
  }
}
.block9__body1__price1 {
  margin: 30px 0 0 0;
  padding: 30px 0 0 0;
  border-top: 1px solid $borderColor1;
}
.block9__body1__price1__title1 {
  @include fontsizeH5;
}
.block9__body1__price1__price1 {
  font-weight: bold;
  color: $fontColor3;
  @include fontsizeH4;
  span {
    font-size: 48px;
    margin: 0 5px 0 0;
  }
}

/* ------------------------------------------------- *
/*        block10
/* ------------------------------------------------- */

.block10 {
  @include sectionMargin1;
}
.block10__body1 {
  background: #fff;
  padding: 20px;
  margin: 2px 0 0 0;
  @include min-sm {
    padding: 30px;
  }
  @include min-md {
    padding: 60px;
  }
}
.block10__body1__list1 {
  margin: 30px 0 0 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    @include fontsizeSmall;
    position: relative;
    padding: 0 0 0 20px;
    margin: 10px 0 0 0;
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: '■';
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
    }
  }
}

/* ------------------------------------------------- *
/*        block11
/* ------------------------------------------------- */

.block11 {
  @include sectionMargin1;
}
.block11__body1 {
  background: #fff;
  padding: 20px;
  margin: 2px 0 0 0;
  @include min-sm {
    padding: 30px;
  }
  @include min-md {
    padding: 60px;
  }
}
.block11__body1__list1 {
  ul {
    list-style: none;
    margin: -5px 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include min-sm {
      margin-top: -10px;
    }
  }
  li {
    display: flex;
    padding: 10px 20px;
    margin: 5px 0 0 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: $bgColor1;
    flex-wrap: wrap;
    color: $fontColor2;
    @include fontsizeSmall;
    @include min-sm {
      width: 32.5%;
      margin: 10px 0 0 0;
    }
    @include min-md {
      width: 24.1%;
      padding: 15px 25px;
    }
    &:nth-of-type(42),
    &:nth-of-type(41),
    &:nth-of-type(37),
    &:nth-of-type(29),
    &:nth-of-type(28),
    &:nth-of-type(12),
    &:nth-of-type(8),
    &:nth-of-type(7) {
      @include min-sm {
        width: 49.5%;
      }
      @include min-md {
        width: 24.1%;
      }
    }
    &.-col2 {
      @include min-sm {
        width: 49.5%;
      }
    }
    &.-active {
      background: $typeAprimaryDefault;
      color: #fff;
      font-weight: bold;
      @at-root {
        body.-typeB .block11__body1__list1 li.-active {
          background: $typeBprimaryDefault;
        }
      }
    }
  }
}
.block11__linkBack1 {
  margin: 30px 0 0 0;
  @include min-md {
    margin: 60px 0 0 0;
  }
}

/* ------------------------------------------------- *
/*        block12
/* ------------------------------------------------- */

.block12 {
  margin: 60px 0 0 0;
  padding: 45px 0 0 0;
  border-top: 1px solid $borderColor1;
}
.block12__list1 {
  list-style: none;
  margin: -15px -5px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  @include min-sm {
    margin: 0 -10px;
  }
  li {
    width: 50%;
    margin: 10px 0 0;
    padding: 0 5px;
    @include min-md {
      width: 25%;
      padding: 0 10px;
      margin: 20px 0 0;
    }
  }
  a {
    display: block;
    padding-top: 55.5%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @at-root {
      body.-typeB .block12__list1 li>a {
        @include min-sm {
          border-radius: 7px;
        }
      }
    }
  }
}

/* ------------------------------------------------- *
/*        block13
/* ------------------------------------------------- */

.block13 {
  @include sectionMargin1;
}
.block13__title1 {
  background: #fff;
}
.block13__body1 {
  background: #fff;
  margin: 2px 0 0 0;
  padding: 20px;
  @include min-sm {
    padding: 30px;
  }
  @include min-md {
    padding: 60px;
  }
}
.block13__btnBack1 {
  margin: 30px 0 0 0;
}

/* ------------------------------------------------- *
/*        block14
/* ------------------------------------------------- */

.block14 {
  margin: 60px 0 0 0;
  @include min-sm {
    margin: 90px 0 0 0;
  }
}
.block14__title1 {
  color: #fff;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  height: 55px;
  background: url('/assets/images/title_image1_1.png') center 0 no-repeat;
  background-size: 355px 55px;
  @include min-sm {
    font-size: 24px;
    width: 1170px;
    height: 75px;
    background: url('/assets/images/title_image1_1_pc.png') center 0 no-repeat;
    background-size: 1170px auto;
    margin: auto;
    text-align: left;
    padding: 10px 0 0 100px;
    background-size: cover;
  }
  span {
    @include min-sm {
      display: block;
      width: 430px;
      text-align: center;
    }
  }
}
.block14__body1 {
  background: #FBFBE9;
  padding: 30px;
  @include min-sm {
    width: 1170px;
    margin: auto;
    padding: 60px 0;
  }
  &.-col2 {
    @include min-sm {
      padding-left: 60px;
      padding-right: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    div {
      &:first-child {
        @include min-sm {
          min-width: 620px;
          max-width: 620px;
          margin: 0 30px 0 0;
        }
      }
    }
    .block14__text1 {
      @include min-sm {
        width: auto;
      }
    }
    .block14__title2 {
      @include min-sm {
        text-align: left;
      }
    }
  }
}
.block14__title2 {
  font-size: 29px;
  line-height: 1.28;
  font-weight: 900;
  color: #4CA223;
  text-align: center;
  @include min-sm {
    font-size: 50px;
  }
  span {
    display: block;
    font-size: 22px;
    @include min-sm {
      font-size: 30px;
    }
    &:first-child {
      @include min-sm {
        display: inline-block;
      }
    }
  }
}
.block14__text1 {
  font-size: 16px;
  line-height: 1.38;
  color: #5F5E5E;
  font-weight: 700;
  margin: 30px 0 0 0;
  @include min-sm {
    font-size: 24px;
    width: 860px;
    margin: 60px auto 0;
  }
}
.block14__images1 {
  @include min-sm {
    display: flex;
    justify-content: space-between;
    width: 1053px;
    margin: 60px auto 0;
  }
  p {
    @include min-sm {
      margin: 0;
    }
    &:nth-of-type(1) {
      @include min-sm {
        min-width: 620px;
        max-width: 620px;
        margin: 0 30px 0 0;
      }
    }
    &:nth-of-type(2) {
      @include min-sm {
        min-width: 400px;
        max-width: 400px;
      }
    }
  }
}
.block14__image1 {
  max-width: 315px;
  margin: 30px auto 0;
  @include min-sm {
    max-width: 100%;
    margin: 45px 0 0 0;
  }
}
.block14__notes1 {
  text-align: right;
  font-size: 14px;
  line-height: 1;
  padding: 0 30px;
  margin: 10px 0 0 0;
  @include min-sm {
    width: 1240px;
    margin: 20px auto 0;
  }
}

/* ------------------------------------------------- *
/*        block15
/* ------------------------------------------------- */

.block15 {
  margin: 60px 0 0 0;
}
.block15__title1 {
  color: #fff;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  height: 55px;
  background: url('/assets/images/title_image1_1.png') center 0 no-repeat;
  background-size: 355px 55px;
  @include min-sm {
    font-size: 24px;
    width: 1170px;
    height: 75px;
    background: url('/assets/images/title_image1_1_pc.png') center 0 no-repeat;
    background-size: 1170px auto;
    margin: auto;
    text-align: left;
    padding: 10px 0 0 100px;
    background-size: cover;
  }
  span {
    @include min-sm {
      display: block;
      width: 430px;
      text-align: center;
    }
  }
}
.block15__body1 {
  background: #FBFBE9;
  padding: 30px;
  @include min-sm {
    padding: 60px 0;
  }
}
.block15__body1__inner1 {
  @include min-sm {
    width: 1170px;
    margin: auto;
  }
}
.block15__unit1 {
  margin: 60px 0 0 0;
  @include min-sm {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &:first-child {
    margin-top: 0;
  }
}
.block15__unit1__image1 {
  @include min-sm {
    min-width: 470px;
  }
}
.block15__unit1__image1__image1 {
  padding: 75% 0 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.block15__unit1__image1__price1 {
  font-size: 15px;
  line-height: 1;
  color: #99000D;
  text-align: center;
  display: block;
  font-weight: 700;
  margin: 20px 0 0 0;
  @include min-sm {
    font-size: 20px;
  }
  span {
    font-size: 39px;
    display: inline-block;
    margin: 0 10px;
    font-weight: 900;
    @include min-sm {
      font-size: 50px;
    }
  }
}
.block15__unit1__body1 {
  @include min-sm {
    min-width: 640px;
  }
}
.block15__unit1__title1 {
  color: #fff;
  font-size: 22px;
  line-height: 1;
  padding: 10px 20px;
  font-weight: 900;
  background: #4CA223;
  margin: 30px 0 0 0;
  @include min-sm {
    margin: 0;
    font-size: 30px;
    padding: 20px;
  }
}
.block15__unit1__text1 {
  font-size: 16px;
  color: #5F5E5E;
  font-weight: 700;
  margin: 15px 0 0 0;
}
.block15__unit1__title2 {
  font-size: 16px;
  line-height: 1;
  background: #fff;
  color: #4CA223;
  font-weight: 700;
  text-align: center;
  padding: 10px 20px;
  margin: 30px 0 0 0;
}
.block15__unit1__table1 {
  font-weight: 700;
  line-height: 1;
  width: 100%;
  margin: 20px 0 0 0;
  th,td {
    padding: 5px 0;
  }
  th {
    font-size: 14px;
    color: #5F5E5E;
    text-align: left;
  }
  td {
    font-size: 16px;
    color: #99000D;
    text-align: right;
  }
}
.block15__notes1 {
  margin: 30px 0 0 0;
  font-size: 12px;
  line-height: 1.58;
  color: #3B3B3B;
  @include min-sm {
    margin: 90px 0 0 0;
  }
  p {
    margin: 30px 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

/* ------------------------------------------------- *
/*        block16
/* ------------------------------------------------- */

.block16 {
  margin: 60px 0 0 0;
}
.block16__title1 {
  color: #fff;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  height: 55px;
  background: url('/assets/images/title_image1_1.png') center 0 no-repeat;
  background-size: 355px 55px;
  @include min-sm {
    font-size: 24px;
    width: 1170px;
    height: 75px;
    background: url('/assets/images/title_image1_1_pc.png') center 0 no-repeat;
    background-size: 1170px auto;
    margin: auto;
    text-align: left;
    padding: 10px 0 0 100px;
    background-size: cover;
  }
  span {
    @include min-sm {
      display: block;
      width: 430px;
      text-align: center;
    }
  }
}
.block16__body1 {
  background: #FBFBE9;
  padding: 30px;
  @include min-sm {
    padding: 60px 0;
  }
}
.block16__list1 {
  list-style: none;
  margin: 0;
  padding: 0;
  @include min-sm {
    width: 1170px;
    margin: auto;
  }
}
.block16__list1__unit1 {
  border-top: 1px dotted #1A1311;
  padding: 30px 0 0 0;
  margin: 30px 0 0 0;
  @include min-sm {
    margin: 0;
    padding: 60px 100px;
  }
  &:first-child {
    border: none;
    padding: 0;
    margin: 0;
    @include min-sm {
      padding: 0 100px 60px;
    }
  }
}
.block16__list1__unit1__q {
  position: relative;
  font-size: 20px;
  line-height: 1.3;
  color: #ED6B00;
  font-weight: 900;
  padding: 0 0 0 70px;
  @include min-sm {
    display: flex;
    align-items: center;
    padding: 0;
  }
  i {
    position: absolute;
    font-size: 22px;
    top: 0;
    left: 0;
    display: block;
    font-style: normal;
    width: 50px;
    height: 50px;
    background: #F99900;
    color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
    @include min-sm {
      position: static;
      margin: 0 20px 0 0;
    }
  }
}
.block16__list1__unit1__a {
  padding: 0 0 0 70px;
  position: relative;
  margin: 30px 0 0 0;
  color: #5F5E5E;
  @include min-sm {
    display: flex;
    padding: 0;
  }
  span {
    color: #A9C300;
  }
  p {
    margin: 30px 0 0 0;
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
  i {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 22px;
    display: block;
    font-style: normal;
    min-width: 50px;
    height: 50px;
    background: #A9C300;
    color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
    @include min-sm {
      position: static;
      margin: 0 20px 0 0;
    }
  }
}
p.block16__list1__unit1__a__image1 {
  max-width: 315px;
  margin: 30px 0 0 -70px;
  @include min-sm {
    max-width: 100%;
    margin: 30px 0 0 0;
  }
  &:first-child {
    margin-top: 0;
  }
}

/* ------------------------------------------------- *
/*        smartCar1
/* ------------------------------------------------- */

.smartCar1 {
  background: #eee;
  @include min-sm {
    padding: 50px 0;
  }
  .smartCar1__inner1 {
    background: #fff;
    @include min-sm {
      width: 980px;
      margin: auto;
    }
  }

/* ------------------------------------------------- *
/*        header2
/* ------------------------------------------------- */

.header2__logo1 {
  padding: 50px 0 0 0;
  max-width: 80%;
  margin: auto;
  @include min-sm {
    width: 722px;
    padding: 160px 0 0 0;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.header2__catch1 {
  font-size: 24px;
  line-height: 1.58;
  font-weight: 900;
  text-align: center;
  color: #6D6E70;
  margin: 40px 0 0 0;
  padding: 0 0 50px 0;
  @include min-sm {
    padding-bottom: 160px;
  }
  span {
    color: #45B035;
  }
  br {
    @include min-sm {
      display: none;
    }
  }
}
.header2__feature1 {
  background: #ABCD03;
  padding: 30px;
  @include min-sm {
    padding: 40px 0;
  }
}
.header2__feature1__list1 {
  list-style: none;
  margin: 0;
  padding: 0;
  @include min-sm {
    width: 900px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.header2__feature1__list1__unit1 {
  background: #45B035;
  border-radius: 10px;
  padding: 20px 30px 20px 30+28+20px;
  position: relative;
  margin: 10px 0 0 0;
  @include min-sm {
    max-width: 210px;
    min-width: 210px;
    min-height: 210px;
    padding: 40px 20px;
    border-radius: 210px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0 15px;
  }
  &:first-child {
    margin-top: 0;
    @include min-sm {
      margin-left: 0;
    }
  }
  &:last-child {
    @include min-md {
      margin-right: 0;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    margin: auto;
    content: '';
    display: block;
    width: 28px;
    height: 24px;
    background: url('https://cms.dd.daihatsu.co.jp/~/media/7bef5e3b0399440882d03ec686277699.ashx?la=ja-jp&hash=334B60C20301B3A9510C83CF422147D939B7B5F4') 0 0 no-repeat;
    background-size: 28px auto;
    @include min-sm {
      top: 20px;
      bottom: inherit;
      left: 0;
      right: 0;
    }
  }
  br {
    display: none;
    @include min-sm {
      display: block;
    }
  }
}
.header2__feature1__list1__unit1__text1 {
  color: #fff;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 900;
  width: 100%;
  span {
    font-size: 15px;
    line-height: 1;
    display: block;
    font-weight: 700;
    margin: 0 0 5px 0;
    @include min-sm {
      margin-bottom: 10px;
    }
  }
}

/* ------------------------------------------------- *
/*        block14
/* ------------------------------------------------- */

.block14 {
  margin: 60px 0 0 0;
  @include min-sm {
    margin: 90px 0 0 0;
  }
}
.block14__title1 {
  color: #fff;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  height: 55px;
  background: url('https://cms.dd.daihatsu.co.jp/~/media/95c29f4b944048b1a3a63474f287e2b6.ashx?la=ja-jp&hash=E1C4DBF2455C36EC6D8E04FFB033A2E43E5192A8') center 0 no-repeat;
  background-size: 355px 55px;
  @include min-sm {
    font-size: 24px;
    width: 920px;
    height: 75px;
    background: url('https://cms.dd.daihatsu.co.jp/~/media/8c9262b1a1314ea7bfe31e40a3530087.ashx?la=ja-jp&hash=71852FC7D34166DB5158D2CD32B6FAC37E6DDF0A') center 0 no-repeat;
    background-size: 920px auto;
    margin: auto;
    text-align: left;
    padding: 10px 0 0 100px;
    background-size: cover;
  }
  span {
    @include min-sm {
      display: block;
      width: 430px;
      text-align: center;
    }
  }
}
.block14__body1 {
  background: #FBFBE9;
  padding: 30px;
  @include min-sm {
    width: 920px;
    margin: auto;
    padding: 60px 0;
  }
  &.-col2 {
    @include min-sm {
      padding-left: 60px;
      padding-right: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    div {
      &:first-child {
        @include min-sm {
          min-width: 440px;
          max-width: 440px;
          margin: 0 30px 0 0;
        }
      }
    }
    .block14__text1 {
      @include min-sm {
        width: auto;
      }
    }
    .block14__title2 {
      @include min-sm {
        text-align: left;
      }
    }
    .block14__image1 {
      @include min-sm {
        margin: 0;
      }
    }
  }
}
.block14__title2 {
  font-size: 29px;
  line-height: 1.28;
  font-weight: 900;
  color: #4CA223;
  text-align: center;
  @include min-sm {
    font-size: 40px;
  }
  span {
    display: block;
    font-size: 22px;
    @include min-sm {
      font-size: 30px;
    }
    &:first-child {
      @include min-sm {
        display: inline-block;
      }
    }
  }
}
.block14__text1 {
  font-size: 16px;
  line-height: 1.38;
  color: #5F5E5E;
  font-weight: 700;
  margin: 30px 0 0 0;
  @include min-sm {
    font-size: 18px;
    width: 860px;
    margin: 60px auto 0;
  }
}
.block14__images1 {
  @include min-sm {
    display: flex;
    justify-content: space-between;
    width: 920-120px;
    margin: 60px auto 0;
  }
  p {
    @include min-sm {
      margin: 0;
    }
  }
  &>.block14__image1 {
    &:nth-of-type(1) {
      @include min-sm {
        min-width: 440px;
        max-width: 440px;
        margin: 0 30px 0 0;
      }
    }
    &:nth-of-type(2) {
      @include min-sm {
        min-width: inherit;
        max-width: inherit;
      }
    }
  }
}
.block14__image1 {
  max-width: 315px;
  margin: 30px auto 0;
  @include min-sm {
    max-width: 100%;
  }
}
.block14__notes1 {
  text-align: right;
  font-size: 14px;
  line-height: 1;
  padding: 0 30px;
  margin: 10px 0 0 0;
  @include min-sm {
    width: 920px;
    margin: 20px auto 0;
    padding: 0;
  }
}

/* ------------------------------------------------- *
/*        block15
/* ------------------------------------------------- */

.block15 {
  margin: 60px 0 0 0;
}
.block15__title1 {
  color: #fff;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  height: 55px;
  background: url('https://cms.dd.daihatsu.co.jp/~/media/95c29f4b944048b1a3a63474f287e2b6.ashx?la=ja-jp&hash=E1C4DBF2455C36EC6D8E04FFB033A2E43E5192A8') center 0 no-repeat;
  background-size: 355px 55px;
  @include min-sm {
    font-size: 24px;
    width: 920px;
    height: 75px;
    background: url('https://cms.dd.daihatsu.co.jp/~/media/8c9262b1a1314ea7bfe31e40a3530087.ashx?la=ja-jp&hash=71852FC7D34166DB5158D2CD32B6FAC37E6DDF0A') center 0 no-repeat;
    background-size: 920px auto;
    margin: auto;
    text-align: left;
    padding: 10px 0 0 100px;
    background-size: cover;
  }
  span {
    @include min-sm {
      display: block;
      width: 430px;
      text-align: center;
    }
  }
}
.block15__body1 {
  background: #FBFBE9;
  padding: 30px;
  @include min-sm {
    padding: 60px 0;
  }
}
.block15__body1__inner1 {
  @include min-sm {
    width: 1170px;
    margin: auto;
  }
}
.block15__unit1 {
  margin: 60px 0 0 0;
  @include min-sm {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &:first-child {
    margin-top: 0;
  }
}
.block15__unit1__image1 {
  @include min-sm {
    min-width: 470px;
  }
}
.block15__unit1__image1__image1 {
  padding: 75% 0 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.block15__unit1__image1__price1 {
  font-size: 15px;
  line-height: 1;
  color: #99000D;
  text-align: center;
  display: block;
  font-weight: 700;
  margin: 20px 0 0 0;
  @include min-sm {
    font-size: 20px;
  }
  span {
    font-size: 39px;
    display: inline-block;
    margin: 0 10px;
    font-weight: 900;
    @include min-sm {
      font-size: 50px;
    }
  }
}
.block15__unit1__body1 {
  @include min-sm {
    min-width: 640px;
  }
}
.block15__unit1__title1 {
  color: #fff;
  font-size: 22px;
  line-height: 1;
  padding: 10px 20px;
  font-weight: 900;
  background: #4CA223;
  margin: 30px 0 0 0;
  @include min-sm {
    margin: 0;
    font-size: 30px;
    padding: 20px;
  }
}
.block15__unit1__text1 {
  font-size: 16px;
  color: #5F5E5E;
  font-weight: 700;
  margin: 15px 0 0 0;
}
.block15__unit1__title2 {
  font-size: 16px;
  line-height: 1;
  background: #fff;
  color: #4CA223;
  font-weight: 700;
  text-align: center;
  padding: 10px 20px;
  margin: 30px 0 0 0;
}
.block15__unit1__table1 {
  font-weight: 700;
  line-height: 1;
  width: 100%;
  margin: 20px 0 0 0;
  th,td {
    padding: 5px 0;
  }
  th {
    font-size: 14px;
    color: #5F5E5E;
    text-align: left;
  }
  td {
    font-size: 16px;
    color: #99000D;
    text-align: right;
  }
}
.block15__notes1 {
  margin: 30px 0 0 0;
  font-size: 12px;
  line-height: 1.58;
  color: #3B3B3B;
  @include min-sm {
    margin: 90px 0 0 0;
  }
  p {
    margin: 30px 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

/* ------------------------------------------------- *
/*        block16
/* ------------------------------------------------- */

.block16 {
  margin: 60px 0 0 0;
}
.block16__title1 {
  color: #fff;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  height: 55px;
  background: url('https://cms.dd.daihatsu.co.jp/~/media/95c29f4b944048b1a3a63474f287e2b6.ashx?la=ja-jp&hash=E1C4DBF2455C36EC6D8E04FFB033A2E43E5192A8') center 0 no-repeat;
  background-size: 355px 55px;
  @include min-sm {
    font-size: 24px;
    width: 920px;
    height: 75px;
    background: url('https://cms.dd.daihatsu.co.jp/~/media/8c9262b1a1314ea7bfe31e40a3530087.ashx?la=ja-jp&hash=71852FC7D34166DB5158D2CD32B6FAC37E6DDF0A') center 0 no-repeat;
    background-size: 920px auto;
    margin: auto;
    text-align: left;
    padding: 10px 0 0 100px;
    background-size: cover;
  }
  span {
    @include min-sm {
      display: block;
      width: 430px;
      text-align: center;
    }
  }
}
.block16__body1 {
  background: #FBFBE9;
  padding: 30px;
  @include min-sm {
    padding: 60px 0;
  }
}
.block16__list1 {
  list-style: none;
  margin: 0;
  padding: 0;
  @include min-sm {
    width: 920px;
    margin: auto;
  }
}
.block16__list1__unit1 {
  border-top: 1px dotted #1A1311;
  padding: 30px 0 0 0;
  margin: 30px 0 0 0;
  @include min-sm {
    padding: 60px;
    margin-top: 0;
  }
  &:first-child {
    border: none;
    padding: 0;
    margin: 0;
    @include min-sm {
      padding: 0 60px 60px;
    }
  }
}
.block16__list1__unit1__q {
  position: relative;
  font-size: 20px;
  line-height: 1.3;
  color: #ED6B00;
  font-weight: 900;
  padding: 0 0 0 70px;
  @include min-sm {
    display: flex;
    align-items: center;
    padding: 0;
  }
  i {
    position: absolute;
    font-size: 22px;
    top: 0;
    left: 0;
    display: block;
    font-style: normal;
    width: 50px;
    height: 50px;
    background: #F99900;
    color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
    @include min-sm {
      position: static;
      margin: 0 20px 0 0;
    }
  }
}
.block16__list1__unit1__a {
  padding: 0 0 0 70px;
  position: relative;
  margin: 30px 0 0 0;
  color: #5F5E5E;
  @include min-sm {
    display: flex;
    padding: 0;
  }
  span {
    color: #A9C300;
  }
  p {
    margin: 30px 0 0 0;
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
  i {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 22px;
    display: block;
    font-style: normal;
    min-width: 50px;
    height: 50px;
    background: #A9C300;
    color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
    @include min-sm {
      position: static;
      margin: 0 20px 0 0;
    }
  }
}
p.block16__list1__unit1__a__image1 {
  max-width: 315px;
  margin: 30px 0 0 -70px;
  @include min-sm {
    max-width: 100%;
    margin: 30px 0 0 0;
  }
  &:first-child {
    margin-top: 0;
  }
}

/* ------------------------------------------------- *
/*        block17
/* ------------------------------------------------- */

.block17 {
  margin: 60px 0 0 0;
  @include min-sm {
    margin: 90px 0 0 0;
  }
}
.block17__title1 {
  color: #fff;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  height: 55px;
  background: url('https://cms.dd.daihatsu.co.jp/~/media/95c29f4b944048b1a3a63474f287e2b6.ashx?la=ja-jp&hash=E1C4DBF2455C36EC6D8E04FFB033A2E43E5192A8') center 0 no-repeat;
  background-size: 355px 55px;
  @include min-sm {
    font-size: 24px;
    width: 920px;
    height: 75px;
    background: url('https://cms.dd.daihatsu.co.jp/~/media/8c9262b1a1314ea7bfe31e40a3530087.ashx?la=ja-jp&hash=71852FC7D34166DB5158D2CD32B6FAC37E6DDF0A') center 0 no-repeat;
    background-size: 920px auto;
    margin: auto;
    text-align: left;
    padding: 10px 0 0 100px;
    background-size: cover;
  }
  span {
    @include min-sm {
      display: block;
      width: 430px;
      text-align: center;
    }
  }
}
.block17__body1 {
  background: #FBFBE9;
  padding: 30px;
  @include min-sm {
    padding: 0;
  }
}
.block17__body1__inner1 {
  @include min-sm {
    width: 920px;
    margin: auto;
    padding: 60px 0;
  }
}
.block17__unit1 {
  margin: 30px 0 0 0;
  padding: 30px 0 0;
  border-top: 1px dotted #000;
  @include min-sm {
    display: flex;
    align-items: center;
    margin: auto;
    padding: 60px;
  }
  &:first-child {
    border: none;
    padding: 0;
    margin: 0;
    @include min-sm {
      margin-top: 0;
      padding: 0 60px 60px;
    }
  }
  &:last-child {
    @include min-sm {
      padding-bottom: 0;
    }
  }
}
.block17__unit1__body1 {
  color: #5F5E5E;
  @include min-sm {
    width: 100%;
    margin: 0 30px 0 0;
  }
}
.block17__unit1__name1 {
  font-size: 20px;
  font-weight: 900;
  &:before {
    content: '■';
    color: #4CA223;
  }
}
.block17__unit1__address1 {
  font-size: 16px;
  font-weight: 700;
  @include min-sm {
    font-size: 20px;
    margin: 10px 0 0 0;
  }
}
.block17__unit1__btn1 {
  margin: 30px 0 0 0;
  @include min-sm {
    margin: 0;
    min-width: 270px;
  }
}
.block17__unit1__btnMore1 {
  a {
    color: #fff;
    font-size: 16px;
    line-height: 1;
    display: block;
    background: #4CA223;
    text-align: center;
    font-weight: 700;
    padding: 10px;
    border-radius: 3px;
  }
}

/* ------------------------------------------------- *
/*        placeholder
/* ------------------------------------------------- */

.anchor1 {
  background: #F5F5F5;
  padding: 15px;
  color: #5F5E5E;
  @include min-sm {
    padding: 30px;
  }
}
.anchor1__inner1 {
  @include min-sm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -20px 0 0 0;
  }
}
.anchor1__unit1 {
  display: block;
  background: #fff;
  padding: 20px;
  margin: 10px 0 0 0;
  font-size: 16px;
  position: relative;
  box-shadow: 0px 0px 10px rgba(#000,0.12);
  @include min-sm {
    width: 286px;
    margin: 20px 0 0 0;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    width: 10px;
    height: 10px;
    border-right: 2px solid #F10010;
    border-bottom: 2px solid #F10010;
    transform: rotate(45deg);
    margin: auto;
  }
  &:first-child {
    margin-top: 0;
    @include min-sm {
      margin-top: 20px;
    }
  }
  span {
    font-size: 14px;
  }
}

/* ------------------------------------------------- *
/*        area1
/* ------------------------------------------------- */

.area1 {
  margin: 60px 0 0 0;
  padding: 0 15px;
  color: #5F5E5E;
  @include min-sm {
    padding: 0 30px;
  }
  &:last-child {
    padding-bottom: 60px;
  }
}
.area1__title1 {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  @include min-sm {
    font-size: 28px;
  }
}
.area1__body1 {
  @include min-sm {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px 0 -15px;
  }
}
.area1__unit1 {
  margin: 15px 0 0 0;
  display: block;
  box-shadow: 0px 0px 10px rgba(#000,0.12);
  @include min-sm {
    width: 286px;
    margin: 30px 16px 0 15px;
  }
  &:first-child {
    margin-top: 30px;
  }
}
.area1__unit1__name1 {
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #F0EEED;
  padding: 15px 15px 15px 45px;
  position: relative;
  color: #5F5E5E;
  @at-root {
    span.area1__unit1 .area1__unit1__name1 {
      padding-left: 15px;
    }
    a.area1__unit1 .area1__unit1__name1 {
      &:before {
        position: absolute;
        top: 18px;
        left: 15px;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background: #F10010;
      }
      &:after {
        position: absolute;
        top: 24px;
        left: 20px;
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(45deg);
      }
    }
  }
}
.area1__unit1__detail1 {
  padding: 14px;
  color: #5F5E5E;
}

}

/* ================================================== *
/*
/*    MEDIA QUERY
/*
/* ================================================== */
