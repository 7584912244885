// breakPoint

$screen-phone      : 480;
$screen-tablet     : 768;
$screen-desktop    : 992;
$screen-lg-desktop : 1200;

@mixin min-xs {
  @media screen and (min-width: $screen-phone + px) { @content; }
}
@mixin min-sm {
  @media screen and (min-width: $screen-tablet + px) { @content; }
}
@mixin min-md {
  @media screen and (min-width: $screen-desktop + px) { @content; }
}
@mixin min-lg {
  @media screen and (min-width: $screen-lg-desktop + px) { @content; }
}

@mixin max-xs {
  @media screen and (max-width: ($screen-phone)-1px) { @content; }
}
@mixin max-sm {
  @media screen and (max-width: ($screen-tablet)-1px) { @content; }
}
@mixin max-md {
  @media screen and (max-width: ($screen-desktop)-1px) { @content; }
}
@mixin max-lg {
  @media screen and (max-width: ($screen-lg-desktop)-1px) { @content; }
}

// sectionMargin

@mixin sectionMargin1 {
  margin-top: 30px;
  @include min-sm {
    margin-top: 60px;
  }
}

// fontSize

@mixin fontsizeDefault {
  font-size: 14px;
  line-height: 1.7;
  @include min-md {
    font-size: 16px;
  }
}

@mixin fontsizeSmall {
  font-size: 12px;
  line-height: 1.6;
  @include min-md {
    font-size: 14px;
  }
}

@mixin fontsizeH1 {
  font-size: 30px;
  line-height: 1.3;
  @include min-md {
    font-size: 36px;
  }
}
@mixin fontsizeH2 {
  font-size: 27px;
  line-height: 1.3;
  @include min-md {
    font-size: 32px;
  }
}
@mixin fontsizeH3 {
  font-size: 24px;
  line-height: 1.3;
  @include min-md {
    font-size: 28px;
  }
}
@mixin fontsizeH4 {
  font-size: 21px;
  line-height: 1.3;
  @include min-md {
    font-size: 24px;
  }
}
@mixin fontsizeH5 {
  font-size: 18px;
  line-height: 1.4;
  @include min-md {
    font-size: 20px;
  }
}
@mixin fontsizeH6 {
  font-size: 14px;
  line-height: 1.4;
  @include min-md {
    font-size: 16px;
  }
}

// 複数行3点リーダー

@mixin abridgement($line-height,$font-size,$lines,$color){
  line-height: $line-height;
  height: $font-size*$line-height*$lines+px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  text-align:justify;
  &:before,
  &:after{
    position: absolute;
  }
  &:before{
    content: '...';
    background: $color;
    right: 0px;
    text-align: center;
    width:1em !important;
    top:$font-size*$line-height*($lines - 1) +px;
  }
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: 0px;
    z-index: 2;
    background: $color;
  }
}